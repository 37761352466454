import React, { useState, useContext } from 'react'
import { Routeconstant } from '../../../routing/utils/RouteConstant'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import IconButton from '@mui/material/IconButton'
import { basename, currencyInfo } from '../../../Config'
import { DateFormat } from '../../../utils/moment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CircularProgress, Menu, MenuItem } from '@mui/material'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import instance from '../../../api/Interceptor'
import { API_PATH } from '../../../constant/apiPath'
import { genrateDownloadLink } from '../../../utils/utility'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../store/context/AuthProvider'
import { toast } from 'react-hot-toast'
import '../statement.css'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import CustomTooltip from '../../../utils/CustomTooltip'
import { Grid } from '@mui/material'
import formatCurrency from './formatCurrency'
import { useIsMobile } from '../../../utils/mobileview'

const Tableview = ({
  data,
  TransactionCount,
  sortColumn,
  setSortColumn,
  getTransactionCollection,
  search,
  filter,
  pageNumber
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isloading, setisLoading] = useState(false)
  const [loadingIndex, setLoadingIndex] = useState(null)
  const navigate = useNavigate()
  const context = useContext(AuthContext)
   const isMobile = useIsMobile();

  const donloadShip = obj => {
    setisLoading(true)

    instance
      .post(API_PATH.DOWNLOAD_DATA, obj)
      .then(res => {
        // console.log("Doc download", res);
        const downloadLink = document.createElement('a')
        const prefix = genrateDownloadLink(
          res.data[0].DocumentModelCollection[0].SC_FileName ?? ''
        )
        downloadLink.href = `${prefix}${
          res.data[0].DocumentModelCollection[0].ImageDataVC ?? ''
        }`
        if (prefix && res.data[0].DocumentModelCollection[0].ImageDataVC) {
          downloadLink.download =
            res.data[0].DocumentModelCollection[0].SC_FileName ?? ''
          downloadLink.click()
        } else {
          toast.error('Invalid document found!')
        }
        setisLoading(false)
      })
      .catch(err => {
        console.log('err', err)
        console.log(err)
        toast.error('No document found!')
      })
      .finally(() => {
        setisLoading(false)
      })
  }
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const setSorting = colName => {
    let sort = {
      key: '',
      direction: ''
    }
    if (sortColumn.direction === 'Asc') {
      sort.key = colName
      sort.direction = 'Desc'
      setSortColumn(sort)
    } else {
      sort.key = colName
      sort.direction = 'Asc'
      setSortColumn(sort)
    }
    getTransactionCollection(0, filter, search, sort,data.length)
  }
  const findOverdueDays = dueDate => {
    const currentDate = new Date()
    const tempDueDateObj = new Date(dueDate)

    currentDate.setHours(0, 0, 0, 0)
    tempDueDateObj.setHours(0, 0, 0, 0)

    const diffTime = currentDate - tempDueDateObj
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    return diffDays > 0 ? diffDays : 0
  }
  // const numberFormat = value => {
  //   const formattedValue = new Intl.NumberFormat(currencyInfo.locale, {
  //     style: 'currency',
  //     currency: currencyInfo.currency
  //   }).format(value)

  //   const withoutCurrencySymbol = formattedValue.replace(
  //     currencyInfo.symbol,
  //     ''
  //   )

  //   return withoutCurrencySymbol
  // }
  // if (TransactionCount === 0) {
  //   return (
  //     <div className="d-flex justify-content-center align-items-center font-change">
  //       No data found!
  //     </div>
  //   )
  // }
  return (
    <>
      <div className='skip-desk-tab'>
        <Grid container className='justify-content-between tableRow-div '>
          <Grid
            item
            xs={0.7}
            className='font-shipment'
            onClick={() => {
              setSorting('TC_TransactionNumber')
            }}
          >
            Invoice No.
            {sortColumn.key !== 'TC_TransactionNumber' ? (
              <ImportExportIcon />
            ) : (
              <>
               {sortColumn.direction === 'Asc' ? (
                 <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={0.7}
            className='font-shipment'
            onClick={() => {
              setSorting('TC_JobNumber')
            }}
          >
            Job No.{' '}
            {sortColumn.key !== 'TC_JobNumber' ? (
              <ImportExportIcon />
            ) : (
              <>
                {sortColumn.direction === 'Asc' ? (
                   <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </>
            )}
          </Grid>
          <Grid item xs={0.8} className='font-shipment'>
            {' '}
            PO/Order Ref No.
          </Grid>
          <Grid item xs={0.8} className='font-shipment'>
            House Bill No.
          </Grid>
          <Grid
            item
            xs={1}
            className='font-shipment'
            onClick={() => {
              setSorting('TC_CustomerOrganizationName')
            }}
          >
            Client{' '}
            {sortColumn.key !== 'TC_CustomerOrganizationName' ? (
              <ImportExportIcon />
            ) : (
              <>
                {sortColumn.direction === 'Asc' ? (
                 <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={0.7}
            className='font-shipment'
            onClick={() => {
              setSorting('TC_TransactionDate')
            }}
          >
            Invoice Date{' '}
            {sortColumn.key !== 'TC_TransactionDate' ? (
              <ImportExportIcon />
            ) : (
              <>
                {sortColumn.direction === 'Asc' ? (
                 <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={0.7}
            className='font-shipment'
            onClick={() => {
              setSorting('TC_DueDate')
            }}
          >
            Due Date{' '}
            {sortColumn.key !== 'TC_DueDate' ? (
              <ImportExportIcon />
            ) : (
              <>
                {sortColumn.direction === 'Asc' ? (
                 <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={0.7}
            className='font-shipment  '
            onClick={() => {
              setSorting('TC_OSTotal')
            }}
          >
            Invoice Amount{' '}
            {sortColumn.key !== 'TC_OSTotal' ? (
              <ImportExportIcon />
            ) : (
              <>
                {sortColumn.direction === 'Asc' ? (
                 <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </>
            )}
            <CustomTooltip of='Statement_Of_Account' forKey='Invoice_Amount' />
          </Grid>
          <Grid item xs={1} className='font-shipment'>
            Compliance Number
          </Grid>
          <Grid item xs={0.67} className='font-shipment'>
            Overdue
          </Grid>

          <Grid
            item
            xs={1}
            className='font-shipment'
            onClick={() => {
              setSorting('TC_OutstandingAmount')
            }}
          >
            <div>
              Outstanding <br /> Amount{' '}
              {sortColumn.key !== 'TC_OutstandingAmount' ? (
                <ImportExportIcon />
              ) : (
                <>
                 {sortColumn.direction === 'Asc' ? (
                 <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
                </>
              )}
              <CustomTooltip
                of='Statement_Of_Account'
                forKey='Outstanding_Amount'
              />
            </div>
          </Grid>
          <Grid item xs={0.5} className='font-shipment'>
            <div>
              Aging <CustomTooltip of='Statement_Of_Account' forKey='Aging' />
            </div>
          </Grid>
          <Grid item xs={0.9} className='font-shipment'>
            Download invoice
          </Grid>
        </Grid>
        {TransactionCount === 0 && !isMobile ? (
          <div className='d-flex justify-content-center align-items-center font-change skip-desk-tab'>
            No data found!
          </div>
        ) : (
          <>
            {data.map((item, index) => {
              const overdueDays = findOverdueDays(item?.DueDate)
              return (
                <Grid
                  container
                  className='justify-content-between tableBody-div'
                  key={index}
                >
                  <Grid
                    item
                    xs={0.7}
                    className='wordWrap  font-shipment'
                    title={item?.TransactionNumber ?? ''}
                  >
                    {item?.TransactionNumber ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={0.7}
                    className='wordWrap font-shipment'
                    title={item?.JobNumber ?? ''}
                    onClick={() => {
                      if (item?.JobNumber.length > 0) {
                        context.setShipmentId(data?.ShipmentId)

                        const path = `${Routeconstant.SHIPMENT_LISTING}/${Routeconstant.DETAILS}`
                        navigate(path, {
                          state: {
                            ShipmentId: item.JobNumber,
                            data: item,
                            HouseBillNumber: item.HBLNumber
                          }
                        })
                        context.setGridViewShipment(false)
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      color: 'var(--color-primary-font)'
                    }}
                  >
                    {item?.JobNumber ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={0.8}
                    className='wordWrap font-shipment'
                    title={item?.PORefNumber ?? ''}
                  >
                    {item?.PORefNumber ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={0.8}
                    className='wordWrap font-shipment'
                    title={item?.HBLNumber ?? ''}
                  >
                    {item?.HBLNumber ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className='wordWrap font-shipment'
                    title={item?.BranchOrganizationName ?? ''}
                  >
                    {item?.BranchOrganizationName ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={0.7}
                    className='wordWrap font-shipment'
                    title={DateFormat(item?.TransactionDate ?? null)}>
                      {DateFormat(item?.TransactionDate ?? null)}
                  </Grid>
                  <Grid
                    item
                    xs={0.7}
                    className='wordWrap font-shipment'
                    title={DateFormat(item?.DueDate ?? null)}>
                      {DateFormat(item?.DueDate ?? null)}
                  </Grid>
                  <Grid
                    item
                    xs={0.7}
                    className='wordWrap font-shipment'
                    title={`${item?.InvoiceCurrency ?? ''}${' '}${
                      item?.InvoiceAmount ?? ''
                    }`}
                  >
                    {`${item?.InvoiceCurrency ?? ''}${' '}${
                      item?.InvoiceAmount ?? ''
                    }`}
                  </Grid>
                  <Grid item xs={1} className=' font-shipment'>
                    <p
                      className='wordWrap m-0'
                      style={{ width: '8rem' }}
                      title={item?.ComplianceNumber ?? null}
                    >
                      {item?.ComplianceNumber ?? ''}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={0.67}
                    className='wordWrap font-shipment'
                    title={overdueDays > 0 ? `${overdueDays} days` : ''}
                  >
                    {overdueDays > 0 ? `${overdueDays} days` : ''}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className='wordWrap font-shipment'
                    title={item?.CalculatedOutstandingAmount ?? ''}
                  >
                    {/* {item?.CalculatedOutstandingAmount ?? ''} */}
                    {/* {numberFormat(425312.34)} */}
                    {formatCurrency(
                      item?.CalculatedOutstandingAmount,
                      item?.LocalCurrencyCode
                    ) ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={0.4}
                    className='wordWrap font-shipment'
                    title={item?.Aging ?? ''}
                  >
                    {item?.Aging ?? ''}
                  </Grid>
                  <Grid
                    item
                    xs={0.9}
                    className='wordWrap d-flex justify-content-center align-items-center font-shipment'
                  >
                    <IconButton
                      onClick={() => {
                        let obj = {
                          InvoiceNumber: item.TransactionNumber,
                          TransactionType: item.TransactionType,
                          Ledger: item.Ledger,
                          CompanyCode: item.CompanyCode
                        }
                        setLoadingIndex(index)
                        donloadShip(obj)
                      }}
                      disabled={isloading}
                    >
                      <DownloadForOfflineOutlinedIcon
                        color={isloading ? '' : 'primary'}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })}
          </>
        )}
      </div>
      {/*--Mobile Respnsive */}
      {TransactionCount === 0 && isMobile ? (
        <div className='d-flex justify-content-center align-items-center font-change skip-card'>
          No data found!
        </div>
      ) : (
        <>
          {data.map((item, index) => {
            const overdueDays = findOverdueDays(item?.DueDate)
            return (
              <div class='row header-check' key={index}>
                <div class='card skip-card'>
                  <div class='card-body'>
                    <div className='border-bottom-sip  d-flex justify-content-between align-items-center'>
                      <div>{item?.TransactionNumber ?? ''}</div>
                      <div>
                        <IconButton
                          onClick={() => {
                            let obj = {
                              InvoiceNumber: item.TransactionNumber,
                              TransactionType: item.TransactionType,
                              Ledger: item.Ledger,
                              CompanyCode: item.CompanyCode
                            }
                            setLoadingIndex(index)
                            donloadShip(obj)
                          }}
                          disabled={isloading}
                        >
                          <DownloadForOfflineOutlinedIcon
                            color={isloading ? '' : 'primary'}
                          />
                        </IconButton>
                      </div>
                    </div>

                    <div className='row p-2 align-items-center'>
                      <div className='col-9' style={{ lineHeight: '15px' }}>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '10px' }}
                        >
                          <li>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Job No:
                            </span>
                          </li>
                          <li
                            className='ps-2'
                            onClick={() => {
                              if (item?.JobNumber.length > 0) {
                                const path = `${Routeconstant.SHIPMENT_LISTING}/${Routeconstant.DETAILS}`
                                navigate(path, {
                                  state: {
                                    ShipmentId: item.JobNumber
                                  }
                                })
                                context.setGridViewShipment(false)
                              }
                            }}
                          >
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {item?.JobNumber ?? ''}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              PO No.:
                            </span>
                          </li>
                          <li className='ps-2'>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {item?.PORefNumber ?? ''}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              House Bill No:
                            </span>
                          </li>
                          <li className='ps-2'>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {item?.HBLNumber ?? ''}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Client:
                            </span>
                          </li>
                          <li className='ps-2'>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {item?.BranchOrganizationName ?? ''}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Due Date:
                            </span>
                          </li>
                          <li className='ps-2'>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {DateFormat(item?.DueDate ?? null)}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Invoice Date:
                            </span>
                          </li>
                          <li className='ps-2'>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {DateFormat(item?.TransactionDate ?? null)}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Compliance number:
                            </span>
                          </li>
                          <li className='ps-2'>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {item?.ComplianceNumber ?? ''}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            {' '}
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Overdue:
                            </span>
                          </li>
                          <li className='ps-2'>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {overdueDays > 0 ? `${overdueDays} days` : ''}
                            </span>
                          </li>
                        </ul>
                        <ul
                          className='list-inline'
                          style={{ display: 'flex', lineHeight: '15px' }}
                        >
                          <li>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              Outstanding Amount:
                            </span>
                          </li>
                          <li className='ps-2'>
                            <span style={{ fontSize: 'var(--fs-lg)' }}>
                              {formatCurrency(
                                item?.CalculatedOutstandingAmount,
                                item?.LocalCurrencyCode
                              ) ?? ''}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div className='col-3 border-left-sip text-center'>
                        <h6>
                          <p
                            style={{
                              fontSize: 'var(--fs-subhead)',
                              minWidth: '50px'
                            }}
                          >
                            Aging
                          </p>
                        </h6>
                        <h4>
                          {' '}
                          <p
                            style={{
                              fontSize: 'var(--fs-label)',
                              fontWeight: 'bolder'
                            }}
                          >
                            {item?.Aging ?? ''}
                          </p>
                        </h4>
                      </div>
                    </div>
                    <div className='row  border-top-sip '>
                      <div class='col-7'>
                        <p>
                          Invoice Amount{' '}
                          <CustomTooltip
                            of='Statement_Of_Account'
                            forKey='Invoice_Amount'
                          />
                        </p>
                      </div>
                      <div
                        class='col-5  text-right'
                        style={{ fontSize: 'var(--fs-label)' }}
                      >
                        <b>{`${item?.InvoiceCurrency ?? ''}${' '}${
                          item?.InvoiceAmount ?? ''
                        }`}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

export default Tableview
